import { RichText } from "prismic-reactjs";
import React from "react";

import styled from "styled-components";
import SplitScrollSection from "./split-scroll-section";
import { Headline } from "./type";

const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    margin: calc(6vw + 5rem) 3rem 6vw;
    gap: 3vw;
    max-height: 100%;
    min-height: 75vh;
  }
`;

const ContentItem = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  p {
    line-height: 234%;
    max-width: 30em;
  }

  h4 {
    max-width: 10em;
  }

  h2 {
    display: inline-flex;
    align-items: baseline;
    strong,
    em {
      margin: 2rem 0 0;
    }
    strong {
      padding: 0 0.5rem;
    }
    em {
      font-family: "DomaineSansTextBold";
      font-size: ${({ theme }) => theme.fonts.h6};
      line-height: 130%;
      letter-spacing: 0.4em;
      text-transform: uppercase;
    }
  }

  em {
    display: inline-flex;
    align-items: baseline;

    span {
      padding: 0 0.5rem;
    }
  }
`;

export default function TourFactsSection({
  data: {
    primary: { layout, section_title, section_body },
    items,
  },
  ...rest
}) {
  return (
    <SplitScrollSection
      layout={layout ? "default" : "reverse"}
      imageList={items}
      {...rest}
    >
      <ContentWrap>
        <ContentItem>
          {section_title && <RichText render={section_title.raw} />}
          {section_body && <RichText render={section_body.raw} />}
        </ContentItem>
      </ContentWrap>
    </SplitScrollSection>
  );
}
