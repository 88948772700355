/**
 * Vendor
 */
import React from "react";

/**
 * Local Components
 */
import styled from "styled-components";
import { SlideCarousel } from "./slide-carousel";

const Wrapper = styled.section`
  margin: 4rem 0;
  @media only screen and ${({ theme }) => theme.device.tablet} {
    margin: 6vw 0;
  }
`;

export default function TourGallery({ data, ...rest }) {
  return (
    <Wrapper {...rest}>
      <SlideCarousel title={data.primary.gallery_title} slides={data.items} />
    </Wrapper>
  );
}
