import React from "react";

import styled from "styled-components";
import {
  GridWrapper,
  GridSectionLeft,
  GridSectionRight,
} from "./layout-components";
import { OutlineImageBox } from "./outline-image-box";

const GridSection = styled(GridWrapper)`
  margin: 4rem 0;
  --row-gap: 4rem;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    margin: 2vw 0;
    --row-gap: unset;
  }

  .image-section {
    --row-gap: 2vw;

    .image-section-box {
      --height: 80vw;
      min-height: 50vh;

      @media only screen and ${({ theme }) => theme.device.tablet} {
        --height: 50vw;
        max-height: 50vw;
      }
    }
  }
`;
const StickyTitle = styled.div`
  display: grid;
  align-items: center;
  justify-content: stretch;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    position: sticky;
    height: max-content;
    top: 0;
  }
`;
export default function SplitScrollSection({
  layout = "default",
  content,
  children,
  imageList,
  ...rest
}) {
  return (
    <GridSection className="split-section" {...rest}>
      {layout === "reverse" ? (
        <>
          <GridSectionLeft className="image-section" mobileOrder={2}>
            {imageList &&
              imageList.map((item, i) => (
                <OutlineImageBox
                  key={i}
                  className="image-section-box"
                  fluid={item?.fluid || item?.images?.fluid}
                />
              ))}
            {content && content}
          </GridSectionLeft>
          <GridSectionRight mobileOrder={1}>
            <StickyTitle>{children && children}</StickyTitle>
          </GridSectionRight>
        </>
      ) : (
        <>
          <GridSectionLeft>
            <StickyTitle>{children && children}</StickyTitle>
          </GridSectionLeft>
          <GridSectionRight className="image-section">
            {imageList &&
              imageList.map((item, i) => (
                <OutlineImageBox
                  key={i}
                  className="image-section-box"
                  fluid={item?.fluid || item?.images?.fluid}
                />
              ))}
            {content && content}
          </GridSectionRight>
        </>
      )}
    </GridSection>
  );
}
