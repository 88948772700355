import React from "react";

import styled from "styled-components";
import Button from "./globals/button";
import AnimatedText from "./john-motion/motion-headline";
import { GridWrapper } from "./layout-components";
import { Headline, Paragraph } from "./type";
import Box from "./john-motion/motion-box/index";

const ContentWrap = styled(GridWrapper)`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  margin: 4rem 0;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    margin: 6vw 0;
  }
`;

export default function TextGroup({
  data: {
    primary: { cta_title, cta_title_size, cta_body, button_link, button_label },
  },
  ...rest
}) {
  return (
    <ContentWrap {...rest}>
      {cta_title && (
        <Headline
          tag="h3"
          size={`${cta_title_size || `h2`} headline`}
          lineLength={1.1}
          aria-label={cta_title}
        >
          <AnimatedText text={cta_title} />
        </Headline>
      )}
      {cta_body && (
        <Paragraph size="paragraph-block" lineLength={3.25}>
          {cta_body}
        </Paragraph>
      )}
      {button_link && button_label && (
        <Box hiddenStyles={{ y: 0 }} transitionOverrides={{ delay: 0.8 }}>
          <Button to={button_link} text={button_label} color="blackOutline" />
        </Box>
      )}
    </ContentWrap>
  );
}
