import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import PageMarquee from "../../components/page-marquee";
import TourSlices from "../../components/tour-slices";
import TextGroup from "../../components/text-group";
import BannerClassy from "../../components/banner-classy";
import PageHead from "../../components/globals/page-head";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function PageTemplate({
  data: {
    prismicTourPage: {
      uid,
      data: { title, feature_image, body },
    },
    prismicHomepage: {
      data: { classy_cta_image, classy_cta_action_text, classy_cta_action },
    },
  },
}) {
  return (
    <>
      <PageHead title={`Tour — ${title}`} />
      <PageMarquee
        title={title}
        titleSize="h1"
        titleLineLength={0.6}
        actionText="Book this space"
        actionLinkTo={`/inquire?desired_space=${uid}`}
        actionStyle="blackOutline"
        layout="default"
        uid={uid}
        image={feature_image}
      />
      <TourSlices body={body} />
      <TextGroup
        data={{
          primary: {
            cta_title: "Make the occasion",
            cta_title_size: "h2",
            button_link: `/inquire?desired_space=${uid}`,
            button_label: "Book this space",
          },
        }}
      />
      <BannerClassy
        classy_cta_image={classy_cta_image}
        classy_cta_action_text={classy_cta_action_text}
        classy_cta_action={`/inquire?desired_space=${uid}`}
      />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query TourPage($uid: String!) {
    prismicTourPage(uid: { eq: $uid }) {
      uid
      data {
        title
        feature_image {
          alt
          fluid(placeholderImgixParams: { blur: 1600, px: 1 }, maxWidth: 2200) {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on PrismicTourPageDataBodyScrollSection {
            id
            primary {
              layout
              body_content_type
              section_title {
                text
                raw
              }
              section_body {
                text
                raw
                html
              }
            }
            items {
              images {
                alt
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 3000
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
          ... on PrismicTourPageDataBodyRoomLayoutSection {
            id
            items {
              image {
                alt
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 3000
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              label_text
            }
            slice_type
          }
          ... on PrismicTourPageDataBodyImageGallery {
            id
            primary {
              gallery_title
            }
            items {
              gallery_images {
                alt
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 3000
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
          ... on PrismicTourPageDataBodyCallToAction {
            id
            primary {
              key
              cta_title
              cta_title_size
              cta_body
              button_link
              button_label
            }
            slice_type
          }
          ... on PrismicTourPageDataBodyVideoSection {
            id
            primary {
              preview_reel_poster {
                alt
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 3000
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              preview_reel {
                id
                url
              }
              lightbox_video {
                id
                url
              }
            }
            slice_type
          }
        }
      }
    }
    prismicHomepage {
      data {
        classy_cta_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 3000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_action_text
        classy_cta_action
      }
    }
  }
`;
