import React from "react";
import styled from "styled-components";
import RoomLayoutSection from "./room-layout-section";
import TextGroup from "./text-group";
import TourFactsSection from "./tour-facts-section";
import TourGallery from "./tour-gallery";
import VideoSection from "./video-section";

const Grid = styled.div``;

export default function TourSlices({ body }) {
  const sliceComponents = {
    scroll_section: TourFactsSection,
    call_to_action: TextGroup,
    room_layout_section: RoomLayoutSection,
    image_gallery: TourGallery,
    video_section: VideoSection,
  };

  return (
    <Grid tabIndex={0}>
      {body.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
          return (
            <SliceComponent
              key={`slice-${index}`}
              id={`tour-section-${index}`}
              data={slice}
            />
          );
        }
        return <pre key={index}>{slice.slice_type}</pre>;
      })}
    </Grid>
  );
}
