/**
 * Vendor
 */
import React from "react";
import ImageBox from "./john-motion/motion-box/image-box";
import OutlineBox from "./outline-box";

export function OutlineImageBox({
  fluid,
  children,
  hiddenStyles,
  parallaxAmount,
  loading,
  ...rest
}) {
  return (
    <OutlineBox {...rest}>
      <ImageBox
        className="image"
        hiddenStyles={{ x: 0, y: 0, scale: 1.1, ...hiddenStyles }}
        transitionOverrides={{ duration: 3 }}
        loading={loading}
        fluid={fluid}
      />
    </OutlineBox>
  );
}
