import React from "react";

import styled from "styled-components";
import { AnimateSharedLayout, motion } from "framer-motion";
import Button from "./globals/button";
import AnimatedText from "./john-motion/motion-headline";
import { GridWrapper } from "./layout-components";
import { Headline, Paragraph } from "./type";
import Box from "./john-motion/motion-box/index";
import { OutlineImageBox } from "./outline-image-box";
import ImageBox from "./john-motion/motion-box/image-box";
import OutlineBox from "./outline-box";
import { PillWrapper } from "./globals/pill-wrapper";

const ImageWrap = styled(OutlineBox)`
  grid-area: 1 / start / 2 / end;
  padding: 0;
  overflow: hidden;
  height: 35vw;
  min-height: 35rem;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    grid-area: 1 / start / 2 / end;
  }

  .image-layout-box {
    height: 100%;
  }
`;

const ImageItem = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LayoutPanel = styled(motion.div)`
  grid-area: 2 / start / 3 / end;
  display: grid;
  align-self: center;
  justify-content: center;

  margin-top: -7rem;
`;

const LayoutPanelInner = styled(PillWrapper)`
  gap: 2rem;
  padding: 0rem 1rem;
  height: 3.8rem;
  max-width: 90%;
  margin: 0 auto;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    gap: 5rem;
    padding: 0 3rem;
  }

  p {
    font-size: ${({ theme }) => theme.fonts.titleLarge};
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: transparent;
    margin: 0;
    z-index: 10;
    position: relative;
    cursor: pointer;
  }

  .pill-outer {
    position: relative;

    span.text {
      position: relative;
      transition: color 1s ${({ theme }) => theme.animation.timingFunction.css};
    }

    &[data-active="true"] span.text {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

const LayoutPill = styled(motion.span)`
  background: ${({ theme }) => theme.colors.golden};
  width: calc(100% + 2rem);
  position: absolute;
  height: calc(100% + 2rem);
  left: -1rem;
  top: -1rem;
  border-radius: 0.85rem;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    width: calc(100% + 5.6rem);
    position: absolute;
    height: 3.6rem;
    left: -2.8rem;
    top: -1rem;
    height: calc(100% + 2rem);
    border-radius: 0.85rem;
  }
`;

export default function RoomLayoutSection({ data: { items }, ...rest }) {
  const [selected, setSelected] = React.useState(0);

  const sharedAnimations = {
    hiddenStyles: { x: 0, y: 0, scale: 1.05 },
    visibleStyles: { opacity: [0, 1, 1, 1] },
    transitionOverrides: { delay: 0, duration: 0.7 },
  };

  return (
    <GridWrapper {...rest}>
      <ImageWrap {...sharedAnimations}>
        {items.map(({ image }, i) => {
          if (i !== selected) return null;
          return (
            <ImageItem key={i} {...sharedAnimations}>
              <ImageBox
                key={i}
                className="image-layout-box"
                fluid={image?.fluid}
                loading="eager"
                {...sharedAnimations}
              />
            </ImageItem>
          );
        })}
      </ImageWrap>
      <LayoutPanel>
        <AnimateSharedLayout>
          <LayoutPanelInner color="black">
            {items.map(({ label_text }, i) => {
              const active = i === selected;
              return (
                <Paragraph
                  size="h6 pill-outer"
                  data-active={active}
                  key={i}
                  onClick={() => setSelected(i)}
                >
                  {active && <LayoutPill layout layoutId="layout-pill" />}
                  <span className="text">{label_text}</span>
                </Paragraph>
              );
            })}
          </LayoutPanelInner>
        </AnimateSharedLayout>
      </LayoutPanel>
    </GridWrapper>
  );
}
