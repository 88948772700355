import React from "react";

import styled from "styled-components";
import {
  GridWrapper,
  GridSectionLeft,
  GridSectionRight,
} from "./layout-components";
import { OutlineImageBox } from "./outline-image-box";
import AnimatedText from "./john-motion/motion-headline/index";
import { Headline } from "./type";
import Button from "./globals/button";
import Box from "./john-motion/motion-box/index";
import RedRoomLogo from "./svg/red-room-logo";

const MarqueeWrap = styled(GridWrapper)`
  margin: 4rem 0 4rem;
  --row-gap: 5rem;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    margin: 2vw 0 6vw;
    --row-gap: 2.5rem;
  }
`;
const MarqueeTitle = styled(GridSectionLeft)`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  text-align: center;
  grid-auto-rows: min-content;
  gap: 1.5rem;

  p {
    margin-bottom: 0;
  }
`;
const MarqueeImage = styled(GridSectionRight)`
  /* min-height: 40vw; */
`;
const MarqueeImageArc = styled(OutlineImageBox)`
  --radius-top: 50vw;
  min-height: 80vw;
  max-height: 50vh;

  @media only screen and ${({ theme }) => theme.device.tablet} {
    --radius-top: 25vw;
    min-height: 35vw;
    max-height: 75vh;
  }
`;

function MarqueeHeadline({ title, titleSize, titleLineLength }) {
  if (title?.includes("Red Room")) {
    return <RedRoomLogo />;
  }
  return (
    <Headline
      tag="h1"
      size={`${titleSize || "h2"} headline`}
      lineLength={titleLineLength || 0.5}
      aria-label={title || "Page Title"}
    >
      <AnimatedText text={title || "Page Title"} />
    </Headline>
  );
}

function MarqueeText({ text, size }) {
  return (
    <Box hiddenStyles={{ x: -50 }}>
      <Headline tag="p" size={size} aria-label={text} lineLength={2}>
        {text}
      </Headline>
    </Box>
  );
}

export default function PageMarquee({
  eyebrow,
  title,
  titleSize,
  titleLineLength,
  description,
  caption,
  actionText,
  actionLinkTo,
  actionOnClick,
  actionStyle,
  image,
  id,
}) {
  return (
    <MarqueeWrap id={id} className="page-marquee">
      <MarqueeTitle>
        {eyebrow && <MarqueeText text={eyebrow} size="h6" />}
        <MarqueeHeadline
          titleSize={titleSize}
          titleLineLength={titleLineLength}
          title={title}
        />
        {description && <MarqueeText text={description} size="p" />}
        {caption && <MarqueeText text={caption} size="p-small" />}
        {!title?.includes("Red Room") && (
          <Box>
            <Button
              to={actionLinkTo}
              onClick={actionOnClick}
              type={actionOnClick ? "onClick" : null}
              text={actionText}
              color={actionStyle}
              data-no-print
            />
          </Box>
        )}
      </MarqueeTitle>
      <MarqueeImage className="marquee-image">
        <MarqueeImageArc
          parallaxAmount={0.15}
          fluid={image && image.fluid}
          loading="eager"
        />
      </MarqueeImage>
    </MarqueeWrap>
  );
}
